import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Button from "~/components/button";
import Accordion from "~/components/accordion";
import UnorderedList from "~/components/List/Unordered";

const Page = () => {
  return (
      <LessonLayout
        current_step={-1}
        lesson={"Lesson 1"}
        color={"ms"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Lesson at a Glance</H2>

          <h2>Lesson Overview</h2>

          <p>
            Students explore features of websites to learn strategies for quickly assessing the quality of a site. Students begin developing and using the rubric that they will employ over the course of the module.
          </p>

          <h3>Major Concepts</h3>

          <UnorderedList>
            <li>A quick scan of a website’s features or characteristics before reading the website’s information or content can lead to more-efficient searches that focus on higher-quality sources of information.</li>
            <li>The presence or absence of specific features of a website can give a quick overview of the quality of the site.</li>
            <li>A rubric is a tool that documents evidence used for decision-making.</li>
          </UnorderedList>

          <h3>Objectives</h3>

          <p>Upon completion of this lesson, students will be able to</p>

          <UnorderedList>
            <li>list features of a website (such as domain type and inclusion of dates, author qualifications, and so forth) that a person should look for when trying to determine the usefulness of a website,</li>
            <li>explain why certain features of a website can lead one to think that it includes either accurate or inaccurate information,</li>
            <li>identify features of a website that correlate with items on a rubric, and</li>
            <li>examine and compare websites using one category on the rubric.</li>
          </UnorderedList>

          <h2>In Advance</h2>

          <p><span className="italic font-semibold">Estimated time for the lesson:</span> 1 45-minute class period</p>

          <p><span className="italic font-semibold">Screens to project:</span> none</p>

          <p className="italic font-semibold">Materials</p>
          <UnorderedList>
            <li><em>Rubric for Analyzing Health Information</em> (optional*): (4 copies per student if using printed version rather than electronic version)</li>
            <li>Science notebooks (Students will use these at different times throughout the lessons.)
            </li>
          </UnorderedList>

          <p>*The electronic version of the rubric is recommended if students are able to save it for use throughout the module. They will be able to add to it as they progress through the lessons. If they use the electronic version, they will need to save 4 copies with different names (one for Lesson 1 websites and 3 for the CBD websites that they will refer to at different points during the lessons).</p>

          <p className="italic font-semibold">Preparation</p>
          <UnorderedList>
            <li>Read through and review information in this lesson.</li>
            <li>Practice using the student activity so you understand what students will do during the lesson.</li>
          </UnorderedList>

          <Accordion headerText="Note to teachers" className="mb-4 mt-5" open={true}>
            <p className="mt-0">Students will begin developing a rubric in this lesson and then add to and use the rubric throughout the remaining lessons. It may be easier for students to keep their rubrics electronically, but you can print copies of the rubric and pass it out to students. They will each need 4 copies of the printed rubric.</p>
          </Accordion>

          <div className="flex flex-col lg:flex-row">
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="purple"
              href="/files/ms/lesson_1/MS__S_full_rubric-scl_final.docx"
              icon="download"
              title="Full Rubric DOCX middle school"
              >
                Full Rubric DOCX
            </Button>
            <Button
              action="secondary"
              className="my-2 lg:m-2"
              color="purple"
              href="/files/ms/lesson_1/MS__S_full_rubric-scl_final.pdf"
              icon="download"
              title="Full Rubric PDF middle school"
              >
                Full Rubric PDF
            </Button>
          </div>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
